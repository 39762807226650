import React from 'react'
import Clients from './Clients/Clients'
import Contacts from './Contacts/Contacts'


import Main from './Main/Main'
import Projects from './Projects/Projects'

function Home() {
  return (
    <>
    <Main/>
    <Projects/>
    <Clients />
    <Contacts />
    
    

    </>
  )
}

export default Home