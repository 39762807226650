
export const bgImages = [
    {
        id:1,
        img:"./bg/bg2.jpg",
        project:"7X500 MVA, 765/3/33KV, 1Ph AUTO TRANSFORMER PACKAGE UNLOADING AND INSTALLATION WORK, CLIENT: L&T-ECC, CUSTOMER : PGCIL 765KV S/S BILASPUR"

    },
    {
        id:2,
        img:"./bg/bg4.jpg",
        project:" INSTALLATION OF 765 KV SUBSTATION AT SOLAPUR, CLIENT: M/s ABB LTD, CUSTOMER: PGCIL "
    },
    {
        id:3,
        img:"./bg/bg5.jpg",
        project:" INSTALLATION  OF CONVERTER TRANSFORMERS 281.6MVA (WEIGHT:310 MT) Y-Y, 800KV DC CLASS AT RP800 HVDC PUGALUR, TAMILNADU. CLIENT: M/s ABB LTD / M/s HITACHI, CUSTOMER: M/s PGCIL "
    },
    {
        id:4,
        img:"./bg/bg24.jpeg",
        project:"SUPPLY FABRICATION AND ERECTION OF COMPLETE STRUCTURE (1200MT), FABRICATION AND ERECTION OF PIPING(61000 INCH MTR), COMPLETE EQUIPMENT ERECTION(960MT) FOR ALUMINA REFINERY EXPANSION PROJECT AT TIKRI, RAYAGADA, ODISHA CLIENT: NEO STRUCTO CONSTRUCTION PVT LTD, CUSTOMER:UTKAL ALUMINA INTERNATIONALLTD"
    },
    {
        id:5,
        img:"./bg/bg9.jpg",
        project:" INSTALLATION  OF THYRISTOR VALVE  FOR EACH  POLE CONSISTING DOUBLE  VALVE STRUCTURESAT RP800 HVDC PUGALUR, TAMILNADU. CLIENT: M/s ABB LTD / M/s HITACHI, CUSTOMER: M/s PGCIL "
    },

    {
        id:6,
        img:"./bg/bg7.jpg",
        project:"ELECTRICAL AND RELATED INSTALLATION FOR MAIN PLANT AT 2X388 MW GMR COMBINED CYCLE POWER PLANT AT VEMAGIRI CLIENT: M/s L&T-ECC, CUSTOMER:GMR"
        
    },
    {
        id:7,
        img:"./bg/bg15.jpg",
        project:"FABRICATION OF COVEYOR GALLARY STRUCTURE FOR CHP, HARDUAGAJ THERMAL POWER STATION , UTTARPRADESH"
    },
    {
        id:8,
        img:"./bg/bg16.jpg",
        project:"INSTALLATION  OF CONVERTER TRANSFORMERS 281.6MVA (WEIGHT:310 MT) Y-Y, 800KV DC CLASS AT RP800 HVDC PUGALUR, TAMILNADU. CLIENT: M/s ABB LTD / M/s HITACHI, CUSTOMER: M/s PGCIL"
    },
    {
        id:9,
        img:"./bg/bg3.jpeg",
        project:"COMPLETE CIVIL WORKS FOR 220/132/33KV SUBSTATION BEGUMGANJ, MP (WORK UNDER PROGRESS) CUSTOMER:M/s ADANI TRANSMISSION LTD "
    },
    {
        id:10,
        img:"./bg/bg20.jpg",
        project:"INSTALLATION OF AC YARD AT RP-800 HVDC RAIGARH (CG)TERMINAL  CLIENT :M/s ABB-HITACHI   CUSTOMER: PGCIL"
    },
    {
        id:11,
        img:"./bg/bg3.jpg",
        project:"COMPLETE ELECTRICAL INSTALLATION  AND ILLUMINATION FOR  2X385 GAS POWER PLANT AT VEMAGIRI. CLIENT: M/s L&T-ECC, CUSTOMER:GMR  ."
    },
    {
        id:12,
        img:"./bg/bg21.jpg",
        project:"INSTALLATION TESTING &COMMISSIONING OF 400KV GIS SUBSTATISON(EXCL.HV TEST)FOR  2X800MW POWER PLANT, GODDA ,JHARKHAND CLIENT:M/s HTGEPL CUSTOMER: M/s ADANI POWER JHARKHAND LIMITED"
    },
    {
        id:13,
        img:"./bg/bg22.jpg",
        project:"INSTALLATION TESTING &COMMISSIONING OF COMPLETE ELECTRICALS, INCLUDES 3X330 MVA GENERATOR TRANSFORMERS,  ST,UAT , IPBD, HT/LT SPBD, HT/LT CABLING ,EARTHING ETC  OF  UNIT -I OF 2X800MW POWER PLANT, GODDA ,JHARKHAND CLIENT:M/s HTGEPL CUSTOMER: M/s ADANI POWER JHARKHAND LIMITED"
    },
    {
        id:14,
        img:"./bg/bg23.jpeg",
        project:"FABRICATION OF CHUTES/TECHNOLOGICAL STRUCTURES AT OUR BHILAI WORKS UNIT FOR MATERIAL HANDLING SYSTEM, CUSTOMER: KALINGA INTERNATIONAL COAL TERMINAL PRADIP PVT LTD"
    },
    
    {
        id:15,
        img:"./bg/bg25.jpeg",
        project:"SUPPLY FABRICATION AND ERECTION OF COMPLETE STRUCTURE (1200MT), FABRICATION AND ERECTION OF PIPING(61000 INCH MTR), COMPLETE EQUIPMENT ERECTION(960MT) FOR ALUMINA REFINERY EXPANSION PROJECT AT TIKRI, RAYAGADA, ODISHA CLIENT: NEO STRUCTO CONSTRUCTION PVT LTD, CUSTOMER:UTKAL ALUMINA INTERNATIONALLTD"
    },

]