



export const closedfabProjects = [
    {
        id:1,
        photoIcon: [
            "./completed/Compl_9.jpg","./completed/Compl_9_1.jpg","./completed/Compl_9_2.jpg","./completed/Compl_9_3.jpg"
        ],

        client: "L&T Ltd",
        customer:"Jindal Power Ltd",
        location:" ",
        project:""
        
    },
    {
        id:2,
        photoIcon: [
            "./completed/Compl_18.jpg","./completed/Compl_18_1.jpg","./completed/Compl_18_2.jpg","./completed/Compl_18_3.jpg"        ],
        client: "Visa Steel Ltd",
        customer:"Visa Steel Ltd",
        location:" ",
        project:""
    },
    {
        id:3,
        photoIcon: [
            "./completed/Ongo_24.jpg","./completed/Ongo_24_1.jpg","./completed/Ongo_24_2.jpg","./completed/Ongo_24_3.jpg"
        ],
        client: "ABB INDIA LTD",
        customer:"BHILAI STEEL PLANT",
        location:"BHILAI",
        project:""
    },
    {
        id:4,
        photoIcon: [
            "./completed/Compl_34.jpg","./completed/Compl_34_1.jpg","./completed/Compl_34_2.jpg","./completed/Compl_34_3.jpg"
        ],
        client: "ABB INDIA LTD",
        customer:"BHILAI STEEL PLANT",
        location:"BHILAI",
        project:""
    },
    
]