



export const closedehvProjects = [
    {
        id:1,
        photoIcon: [
            "./completed/Compl_19.jpg","./completed/Compl_19_1.jpg"
        ],

        client: "HCL",
        customer:"HCL",
        location:"JHARSUGUDA",
        project:"Refurbishment of 66 KV Switchyard & Main receiving substation for Hindustan Copper Limited, Jhagadia, Bharuch"
        
    },
    {
        id:2,
        photoIcon: [
            "./completed/Compl_5.jpg","./completed/Compl_5_1.jpg","./completed/Compl_5_2.jpg","./completed/Compl_5_3.jpg"
        ],
        client: "ABB INDIA LTD",
        customer:"PGCIL",
        location:"SOLAPUR",
        project:""
    },
    {
        id:3,
        photoIcon: [
            "./completed/Compl_10.jpg","./completed/Compl_10_1.jpg","./completed/Compl_10_2.jpg","./completed/Compl_10_3.jpg"
        ],
        client: "L&T LTD (ECC-D)",
        customer:"DB POWAR",
        location:"KHARSIA",
        project:""
    },
    {
        id:4,
        photoIcon: [
            "./completed/Compl_20.jpg","./completed/Compl_20_1.jpg","./completed/Compl_20_2.jpg","./completed/Compl_20_3.jpg"
        ],
        client: "Areva T&D India Ltd",
        customer:"EMCO GMR Energy",
        location:"WARORA",
        project:""
    },
    {
        id:5,
        photoIcon: [
            "./completed/Compl_22.jpg","./completed/Compl_22_1.jpg","./completed/Compl_22_2.jpg","./completed/Compl_22_3.jpg"
        ],
        client: "L&T LTD",
        customer:"GMR",
        location:"",
        project:""
    },
    {
        id:6,
        photoIcon: [
            "./completed/Compl_21.jpg","./completed/Compl_21_1.jpg","./completed/Compl_21_2.jpg","./completed/Compl_21_3.jpg"
        ],
        client: "L&T LTD (ECC-D)",
        customer:"PGCIL",
        location:"BILASPUR",
        project:""
    },
    {
        id:8,
        photoIcon: [
            "./completed/Compl_6.jpg","./completed/Compl_6_1.jpg","./completed/Compl_6_2.jpg","./completed/Compl_6_3.jpg"
        ],
        client: "ABB Ltd",
        customer:"VEDANTA ALUMINIUM LTD",
        location:"JHARSUGUDA",
        project:""
    },
    {
        id:9,
        photoIcon: [
            "./completed/Compl_8.jpg","./completed/Compl_8_1.jpg","./completed/Compl_8_2.jpg","./completed/Compl_8_3.jpg"
        ],
        client: "ABB Ltd",
        customer:"PGCIL",
        location:"PUNE",
        project:""
    },
    {
        id:10,
        photoIcon: [
            "./completed/Compl_23.jpg","./completed/Compl_23_1.jpg","./completed/Compl_23_2.jpg","./completed/Compl_23_3.jpg"
        ],
        client: "ABB Ltd",
        customer:"PGCIL",
        location:"SOLAPUR",
        project:""
    },
    {
        id:11,
        photoIcon: [
            "./completed/Compl_24.jpg","./completed/Compl_24_3.jpg"
        ],
        client: "ABB Ltd",
        customer:"JPL",
        location:"RAIGARH",
        project:""
    },
 
    {
        id:13,
        photoIcon: [
            "./completed/Compl_33.jpg","./completed/Compl_33_1.jpg","./completed/Compl_33_2.jpg","./completed/Compl_33_3.jpg"
        ],
        client: "L&T LTD",
        customer:"SAIL BSP",
        location:"BHILAI",
        project:""
    },
    
    {
        id:15,
        photoIcon: [
            "./completed/Compl_28.jpg","./completed/Compl_28_1.jpg","./completed/Compl_28_2.jpg","./completed/Compl_28_3.jpg"
        ],
        client: "ABB Ltd",
        customer:"CSEB",
        location:"BEMTERA",
        project:""
    },
    {
        id:16,
        photoIcon: [
            "./completed/Compl_29.jpg","./completed/Compl_29_1.jpg","./completed/Compl_29_2.jpg","./completed/Compl_29_3.jpg"
        ],
        client: "ABB Ltd",
        customer:"CSEB",
        location:"KHARASIA",
        project:""
    },
    {
        id:17,
        photoIcon: [
            "./completed/Compl_30.jpg","./completed/Compl_30_1.jpg","./completed/Compl_30_2.jpg","./completed/Compl_30_3.jpg"
        ],
        client: "ABB Ltd",
        customer:"CSEB",
        location:"ATARI",
        project:""
    },
    {
        id:18,
        photoIcon: [
            "./completed/Compl_31.jpg","./completed/Compl_31_1.jpg","./completed/Compl_31_2.jpg","./completed/Compl_31_3.jpg"
        ],
        client: "ABB Ltd",
        customer:"CSEB",
        location:"RAIGARH",
        project:""
    },
    {
        id:19,
        photoIcon: [
            "./completed/Compl_32.jpg","./completed/Compl_32_1.jpg","./completed/Compl_32_2.jpg","./completed/Compl_32_3.jpg"
        ],
        client: "ABB Ltd",
        customer:"CSEB",
        location:"RAIGARH",
        project:""
    },
    {
        id:20,
        photoIcon: [
            "./ongoing/Ongo_30.jpg", "./ongoing/Ongo_30_1.jpg","./ongoing/Ongo_30_2.jpg","./ongoing/Ongo_30_3.jpg"
        ],

        client: "ABB Ltd",
        customer:"PGCIL",
        location:"",
        project:""
        
    },
    {
        id:21,
        photoIcon: [
            "./ongoing/Ongo_31.jpg", "./ongoing/Ongo_31_1.jpg","./ongoing/Ongo_31_2.jpg","./ongoing/Ongo_31_3.jpg"
        ],
        client: "L&T Ltd",
        customer:"PGCIL",
        location:"",
        project:""
    },
    {
        id:22,
        photoIcon: [
            "./ongoing/Ongo_13.jpg", "./ongoing/Ongo_13_1.jpg","./ongoing/Ongo_13_2.jpg","./ongoing/Ongo_13_3.jpg"
        ],
        client: "EMC",
        customer:"PGCIL",
        location:"",
        project:""
    },
    {
        id:23,
        photoIcon: [
            "./ongoing/Ongo_17.jpg", "./ongoing/Ongo_17_1.jpg","./ongoing/Ongo_17_2.jpg","./ongoing/Ongo_17_3.jpg"
        ],
        client: "ABB Ltd",
        customer:"ADANI",
        location:"MORENA",
        project: "ERECTION SERVICE FOR ADANI 400/220KV MORENA SUBSTATION PROJECT, MORENA(MP)"
    },
    {
        id:24,
        photoIcon: [
            "./ongoing/Ongo_18.jpg", "./ongoing/Ongo_18_1.jpg","./ongoing/Ongo_18_2.jpg","./ongoing/Ongo_18_3.jpg"
        ],
        client: "ABB INDIA LTD",
        customer:"ADANI",
        location:"RAJNADGAUN",
        project:"ERECTION SERVICE FOR ADANI 765KV SWITCHYARD PROJECT, RAJNANDGAUN"
    },
    {
        id:25,
        photoIcon: [
            "./ongoing/Ongo_19.jpg", "./ongoing/Ongo_19_1.jpg","./ongoing/Ongo_19_2.jpg","./ongoing/Ongo_19_3.jpg"
        ],
        client: "KALPATARU",
        customer:"PGCIL",
        location:"SOLAPUR",
        project:"ERECTION SERVICE FOR NTPC 400KV SWITCHYARD PROJECT, SOLAPUR."
    },
    
    {
        id:12,
        photoIcon: [
            "./completed/Compl_13.jpg","./completed/Compl_13_1.jpg","./completed/Compl_13_2.jpg","./completed/Compl_13_3.jpg"
        ],
        client: "L&T LTD",
        customer:"NSPCL",
        location:"BHILAI",
        project:"400/220KV SUBSTATION "
    },
    {
        id:14,
        photoIcon: [
            "./completed/Compl_27.jpg","./completed/Compl_27_1.jpg","./completed/Compl_27_2.jpg","./completed/Compl_27_3.jpg"
        ],
        client: "L&T LTD",
        customer:"LANCO",
        location:"KORBA",
        project:"400KV SWICTYARD ERECTION WORK"
    },
]