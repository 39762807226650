

export const clientImg = [
    {
        id:1,
        imageurl:"./clientimages/Client1.jpg",
        client: 'ABB'
    },
    {
        id:2,
        imageurl:"./clientimages/Client2.jpg",
        client: 'BHEL'
    },
    {
        id:3,
        imageurl:"./clientimages/Client3.jpg",
        client: 'HCL'
    },
    {
        id:4,
        imageurl:"./clientimages/Client4.jpg",
        client: 'KWPCL'
    },
    {
        id:5,
        imageurl:"./clientimages/Client5.jpg",
        client: 'MECON'
    },
    {
        id:6,
        imageurl:"./clientimages/Client6.jpg",
        client: 'SAIL'
    },
   
    {
        id:7,
        imageurl:"./clientimages/Client7.jpg",
        client: 'SAIL'
    },
    {
        id:8,
        imageurl:"./clientimages/Client8.jpg",
        client: 'SAIL'
    },
    {
        id:9,
        imageurl:"./clientimages/Client9.jpg",
        client: 'SAIL'
    },
    {
        id:10,
        imageurl:"./clientimages/Client10.jpg",
        client: 'SAIL'
    },
    {
        id:11,
        imageurl:"./clientimages/Client11.jpg",
        client: 'SAIL'
    },
    {
        id:12,
        imageurl:"./clientimages/Client12.jpg",
        client: 'SAIL'
    },
    {
        id:13,
        imageurl:"./clientimages/Client13.jpg",
        client: 'SAIL'
    },
    {
        id:14,
        imageurl:"./clientimages/Client14.jpg",
        client: 'SAIL'
    },
    {
        id:15,
        imageurl:"./clientimages/Client15.jpg",
        client: 'SAIL'
    },
    {
        id:16,
        imageurl:"./clientimages/Client16.jpg",
        client: 'SAIL'
    },
    {
        id:17,
        imageurl:"./clientimages/Client17.jpg",
        client: 'SAIL'
    },
    {
        id:18,
        imageurl:"./clientimages/Client18.jpg",
        client: 'SAIL'
    },
    {
        id:19,
        imageurl:"./clientimages/Client19.jpg",
        client: 'SAIL'
    },
    {
        id:20,
        imageurl:"./clientimages/Client20.jpg",
        client: 'SAIL'
    },
    {
        id:21,
        imageurl:"./clientimages/Client21.jpg",
        client: 'SAIL'
    },
    {
        id:22,
        imageurl:"./clientimages/Client22.jpg",
        client: 'SAIL'
    },
    {
        id:23,
        imageurl:"./clientimages/Client24.jpg",
        client: 'SAIL'
    },
    {
        id:24,
        imageurl:"./clientimages/Client25.jpg",
        client: 'SAIL'
    },
    
]