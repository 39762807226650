


export const careerData = [
    {
        id:1,
        title: 'Administration & Support',
    
    },
    {
        id:2,
        title: 'Engineering',
    
    },
    {
        id:3,
        title: 'Finance',
    
    },
    {
        id:4,
        title: 'Human Resourcess',
    
    },
    {
        id:5,
        title: 'Information System',
    
    },
    {
        id:6,
        title: 'Internal Audit',
    
    },
    {
        id:7,
        title: 'Legal',
    
    },
    {
        id:8,
        title: 'Stratagy & Consulting',
    
    },
    {
        id:9,
        title: 'Project Management',
    
    },
    {
        id:10,
        title: 'Quality Management',
    
    },
    {
        id:11,
        title: 'Sales & Marketing',
    
    },
    {
        id:12,
        title: 'Safety Management',
    
    },
]