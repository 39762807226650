

export const ongoCivilProjects =[

    {
        id:1,
        photoIcon: [
            "./ongoing/Ongo_41.jpeg", 
        ],
        client: "M/s ADANI TRANSMISSION LTD.",
        customer:"M/s ADANI TRANSMISSION LTD.",
        location:"BEGUMGANJ, MADHYAPRADESH",
        project:"COMPLETE CIVIL WORKS FOR 220/132/33KV SUBSTATION BEGUMGANJ, MP (WORK UNDER PROGRESS) "
    },
    {
        id:1,
        photoIcon: [
            "./ongoing/Ongo_42.jpeg", 
        ],
        client: "M/s ADANI TRANSMISSION LTD.",
        customer:"M/s ADANI TRANSMISSION LTD.",
        location:"BEGUMGANJ, MADHYAPRADESH",
        project:"COMPLETE CIVIL WORKS FOR 220/132/33KV SUBSTATION BEGUMGANJ, MP (WORK UNDER PROGRESS) "
    },
    {
        id:1,
        photoIcon: [
            "./ongoing/Ongo_43.jpeg", 
        ],
        client: "M/s ADANI TRANSMISSION LTD.",
        customer:"M/s ADANI TRANSMISSION LTD.",
        location:"BEGUMGANJ, MADHYAPRADESH",
        project:"COMPLETE CIVIL WORKS FOR 220/132/33KV SUBSTATION BEGUMGANJ, MP (WORK UNDER PROGRESS) "
    },
    {
        id:1,
        photoIcon: [
            "./ongoing/Ongo_44.jpeg", 
        ],
        client: "M/s ADANI TRANSMISSION LTD.",
        customer:"M/s ADANI TRANSMISSION LTD.",
        location:"BEGUMGANJ, MADHYAPRADESH",
        project:"COMPLETE CIVIL WORKS FOR 220/132/33KV SUBSTATION BEGUMGANJ, MP (WORK UNDER PROGRESS) "
    },
    

]